<template>
  <div>
    <el-dialog
      class="el-dialog-cd-note"
      width="90%"
      title="Select Invoice"
      :visible="dialogSelectInvoice"
      @open="handleSelectInvoiceDialogOpen"
      @close="handleInvoiceDialogClose"
    >
      <div>
        <el-table :data="invoiceTableData" v-loading="isInvoiceTableLoading" height="48vh" :header-cell-style="{ color: '#909399' }" :cell-style="{padding: '0', height: '60px'}">
          <el-table-column width="55">
            <template v-slot="scope">
            <el-radio
              :label="scope.row"
              v-model="invoiceRadioData"
              @change="handleInvoiceRadioSelectChange"
            >
            <i></i>
            </el-radio>
            </template>
          </el-table-column>
          <el-table-column label="Invoice No." prop="userEnteredInvoiceNo" show-overflow-tooltip/>
          <el-table-column label="Company" show-overflow-tooltip>
            <template v-slot="scope">
              {{ isSellerView ? scope.row.buyerCompanyName : scope.row.sellerCompanyName }}
            </template>
          </el-table-column>
          <el-table-column label="Vessel/ Terminal" show-overflow-tooltip>
            <template v-slot="scope">
              <div>{{ scope.row.orderVesselName ? scope.row.orderVesselName : scope.row.orderTerminal }}</div>
            </template>
          </el-table-column>
          <el-table-column label="Invoice Date">
            <template v-slot="scope">
              <div>{{ formatDate(new Date(scope.row.invoiceDate)) }}</div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="el-dialog__footer">
        <el-button
          round  type="primary"
          @click="handleConfirmInvoiceClick"
        >
          Confirm
        </el-button>
        <el-button
          outline
          round

          @click="handleInvoiceDialogClose"
        >
          Cancel
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { formatDate } from '@/utils/dateTime.js'
import { getInvoices } from '@/services/modules/invoice'
import { INVOICE_STATUS } from '@/constants.js'
export default {
  name: 'DialogSelectInvoice',
  data () {
    const invoicePagination = {
      pageSize: 50,
      pageNumber: 1,
      total: 0
    }
    return {
      invoicePagination,
      isInvoiceTableLoading: true,
      invoiceTableData: [],
      invoiceRadioData: null,
      invoiceSelected: null
    }
  },
  props: {
    dialogSelectInvoice: Boolean,
    isSellerView: Boolean,
    propsInvoiceSelected: Object
  },
  methods: {
    formatDate,
    handleSelectInvoiceDialogOpen () {
      this.isInvoiceTableLoading = true
      let statusKey, status
      if (this.isSellerView) {
        statusKey = 'sellerInvoiceStatus'
        status = `('${INVOICE_STATUS.SENT}', '${INVOICE_STATUS.OUTSTANDING}', '${INVOICE_STATUS.PAID}')`
      } else {
        statusKey = 'buyerInvoiceStatus'
        status = `('${INVOICE_STATUS.AUTHORIZED_SIGNATORY_APPROVED}', '${INVOICE_STATUS.OUTSTANDING}', '${INVOICE_STATUS.PAID}')`
      }
      const query = `${statusKey} in ${status} and invoiceType:'COMMERCIAL'&sort=updatedAt DESC&isSellerView=${this.isSellerView}`
      getInvoices(50, 0, query)
        .then(res => {
          if (res?.code === 1000) {
            this.invoiceTableData = res.data.content
            this.invoicePagination.total = res.data.totalElements
            this.invoicePagination.pageSize = res.data.pageable.pageSize
            if (this.propsInvoiceSelected) {
              this.invoiceRadioData = this.invoiceTableData.find(d => d.id === this.propsInvoiceSelected.id)
            }
            this.isInvoiceTableLoading = false
          } else {
            this.isInvoiceTableLoading = false
          }
        })
        .catch(e => { this.isInvoiceTableLoading = false })
    },
    handleConfirmInvoiceClick () {
      this.$emit('handleConfirmInvoiceClick', this.invoiceSelected)
    },
    handleInvoiceDialogClose () {
      this.$emit('handleInvoiceDialogClose')
    },
    handleInvoiceRadioSelectChange (selection) {
      this.invoiceSelected = selection
    }
  }
}
</script>
<style scoped lang="scss">
  @import "../index.scss";
</style>
